import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Routes } from "../../../routes";
import PSIcon from "../../components/PSIcon";
import PSModalFAD from "../../components/organisms/PSModalFAD";
import { getFadError } from "../../../util/Util";
import { showErrorToast } from "../../../util/Constant";
import { checkDeviceSpecifications, getParams, setFadError } from "./utils/functions";
import { ERROR_CODE, ERROR_MESSAGE, EVENT_MODULE, MESSAGE_ERROR } from "./utils/constant";
import 'react-toastify/dist/ReactToastify.css';
import './index.scss'

const ORIGIN_DEFAULT = process.env.REACT_APP_KONFIDI
const KONFIDI_VALIDATION = process.env.REACT_APP_KONFIDI_VALIDATION

const INITIAL_MESSAGE = { icon: '', title: '', description: '' }
const PERCENTAGE_KONFIDI = 80
const TIMEOUT = 4000

const ValidationIframe = ({ onClose, documentType, documentNumber, validationPercentage, showIframe }) => {
    const iframeRef = useRef(null);
    const history = useHistory()
    const [showFAD, setShowFAD] = useState(showIframe)
    const [showAlertAF, setShowAlertAF] = useState(false)
    const [modalMessage, setModalMessage] = useState(INITIAL_MESSAGE)

    const handlerIcon = () => {
        onClose()
    }
    const checkDevice = () => checkDeviceSpecifications()
    const routeIframe = () => {
        if (showIframe && iframeRef?.current) {
            const params = getParams(documentType, documentNumber, validationPercentage)
            const route = `${KONFIDI_VALIDATION}?${params}`
            iframeRef.current.src = route;
        }
    }

    const handlerInit = () => {
        let errorCount = getFadError(documentNumber) || 0;
        errorCount += 1;
        setFadError(documentNumber, errorCount);
    };
    const compareFacial = (percentage, id) => {

        try {
            if (percentage >= PERCENTAGE_KONFIDI) {
                setTimeout(() => {
                    history.push({
                        pathname: Routes.SIGN_UP_VALIDATION,
                        state: { idValidation: id }
                    });
                }, TIMEOUT)
            } else {
                handlerInit()
                // TODO: Create error message
                /*const errorCount = getFadError(documentNumber)
                if (errorCount >= MAX_ATTEMPTS) {
                    setModalMessage({
                        title: MESSAGE_ERROR.BLOCKED.TITLE,
                        description: MESSAGE_ERROR.BLOCKED.DESCRIPTION
                    })
                    setShowAlertAF(true)
                }*/
                    setTimeout(() => {
                        setShowFAD(false);
                        routeIframe()
                        setShowFAD(true);
                    }, TIMEOUT);
                
            }
        } catch (e) {
            handlerInit()
            setShowFAD(false);
            window.removeEventListener('message', handleMessage);
            onClose();
        }
    };

    const handleMessage = (message) => {
        if (message?.origin.includes(ORIGIN_DEFAULT)) {
            switch (message?.data?.event) {
                case EVENT_MODULE?.PROCESS_INIT:
                    console.log('PROCESS_INIT');
                    if (!checkDevice()) {
                        setModalMessage({
                            icon: MESSAGE_ERROR.REQUIREMENTS.ICON,
                            title: MESSAGE_ERROR.REQUIREMENTS.TITLE,
                            description: MESSAGE_ERROR.REQUIREMENTS.DESCRIPTION
                        })
                        setShowAlertAF(true)
                        return;
                    }
                    break;
                case EVENT_MODULE?.PROCESS_ERROR:
                    console.log('PROCESS_ERROR');
                    const errorCode = message?.data?.data?.code
                    switch (errorCode) {
                        case ERROR_CODE?.NO_DATA:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.AUTH_FAD:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.GET_KEY:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.PROOF_LIFENESS:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.AUTH_PROTECTA:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.PROTECTA_DATA:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.COMPARE_FACES:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.SAVE_VALIDATION:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.INTERNAL_ERROR:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.LOCATION_PERMISSION:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.CAMERA_PERMISSION:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;

                        case ERROR_CODE?.FULLSCREEN_PERMISSION:
                            showErrorToast(ERROR_MESSAGE[errorCode])
                            break;
                        default:
                            showErrorToast("E00 : No se encontro código de error")                  
                    }
                    window.removeEventListener('message', handleMessage);
                    setShowFAD(false);
                    onClose()
                    break;
                case EVENT_MODULE?.PROCESS_COMPLETED:
                    console.log('PROCESS_COMPLETED');
                    const {
                        facialBiometricPercentage,
                        idValidation
                    } = message?.data?.data
                    compareFacial(
                        facialBiometricPercentage,
                        idValidation
                    )
                    break;
                default:
                    console.warn('Evento desconocido:', message?.data?.event);
                    window.removeEventListener('message', handleMessage);
                    setShowFAD(false);
                    onClose()
                    break;
            }
        } else return
    }
    const postMessage = () => {
        window.addEventListener('message', handleMessage);
    }

    useEffect(() => {
        routeIframe();
    }, [documentType, documentNumber, showFAD]);

    useEffect(() => {
        setShowFAD(showIframe);
    }, [showIframe]);

    useEffect(() => {
        postMessage()
    }, [])

    return (
        showFAD && (
            <>
                {
                    showAlertAF ? (
                        <PSModalFAD
                            isVisible={showAlertAF}
                            handlerIcon={handlerIcon}
                            icon={modalMessage.icon}
                            title={modalMessage.title}
                            description={modalMessage.description}
                        />
                    ) : 
                        <div className="modal-container">
                            <PSIcon
                                onClick={onClose}
                                type="close"
                                className="modal-close-icon"
                            />
                            <iframe
                                className="container-iframe"
                                ref={iframeRef}
                                title="validation"
                                allow="geolocation; camera; fullscreen"
                                frameBorder="0"
                            />
                        </div>
                }
            </>
        )
    );
};

ValidationIframe.propTypes = {
    onClose: PropTypes.func,
    handlerIcon: PropTypes.func
}

export default ValidationIframe